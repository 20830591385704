<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
           <div class="d-flex" style="align-items: center;">
              <i class="fa fa-solid fa fa-bullhorn " aria-hidden="true" style="font-size: 24px; margin-right: 3px; color: var(--iq-primary);"></i>
              <h4 class="card-title ml-2">{{cvCardTitle}}</h4>
            </div>
          </template>
          <template v-slot:body>
            <form action="#" v-if="vmCustomFormFieldsFormData && Object.keys(vmCustomFormFieldsFormData).length > 0">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validationform_field_type">
                    {{cvFormFieldTypeLabel}}</label
                  >
                  <p class="primary-color">Allowed Values:&nbsp;[INPUT,&nbsp;DROPDOWN]</p>
                  <input
                    v-model="vmCustomFormFieldsFormData.form_field_type"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationform_field_label">
                    {{cvFormFieldLabelLabel}}</label
                  >
                  <input
                    v-model="vmCustomFormFieldsFormData.form_field_label"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationform_field_variable">
                    {{cvFormFieldVariableLabel}}</label
                  >
                  <input
                    v-model="vmCustomFormFieldsFormData.form_field_variable"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationform_field_data_type">
                    {{cvFormFieldDataTypeLabel}}</label
                  >
                  <p class="primary-color">Allowed Values:&nbsp;[varchar(128)]</p>
                  <input
                    v-model="vmCustomFormFieldsFormData.form_field_data_type"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <!-- Data Schema(only for JSON) -->
                <div class="col-md-12 mb-3">
                  <label for="validationdata_schema">
                    {{cvDataSchemaLabel}}</label
                  >
                  <textarea :placeholder='JSON.stringify([{"id": 1,"name": "Choice 1"},{"id": 2,"name": "Choice 2"}], undefined, 4)' v-model="vmCustomFormFieldsFormData.form_dropdown_structure" type="json" @input="formatJSON" class="form-control textarea" id="json">
                  </textarea>
                  <span class="primary-color" v-if="invalidJSONMsg">{{invalidJSONMsg}}</span>
                </div><!-- Data Schema(only for JSON) -->
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="customFormFieldsEdit()">{{cvSubmitBtn}}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { CustomFormFieldss } from "../../../FackApi/api/CustomFormFields.js"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"

export default {
  name: "CustomFormFieldsEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propCustomFormFieldsObj: {
      type: Object,
      default: function () {
        return {
          "form_id": "",
          "form_field_name": "",
          "form_field_type": "",
          "form_field_label": "",
          "form_field_variable": "",
          "form_field_data_type": "",
          "form_field_rank": "",
          "created_on": "",
          "modified_on": "",
          "deleted": ""
        }
      }
    }
  },
  data () {
    return {
      cvCardTitle: "Edit custom Form Fields",
      cvSubmitBtn: "Edit",
      cvFormFieldTypeLabel: "form field type",
      cvFormFieldLabelLabel: "form field label",
      cvFormFieldVariableLabel: "form field variable",
      cvFormFieldDataTypeLabel: "form field data type",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "CustomFormFields Updated",
      vmCustomFormFieldsFormData: {},
      cvDataSchemaLabel: "Drop Down Field Structure",
      invalidJSONMsg: null
    }
  },
  mounted () {
    socialvue.index()
    this.customFormFieldsView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmCustomFormFieldsFormData) {
          if (!this.vmCustomFormFieldsFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * customFormFieldsView
     */
    async customFormFieldsView () {
      try {
        if (this.propOpenedInModal) {
          this.vmCustomFormFieldsFormData = this.propCustomFormFieldsObj
        }
        else {
          let customFormFieldsId = this.$route.params.cff_id
          let customFormFieldsViewResp = await CustomFormFieldss.customFormFieldsView(this, customFormFieldsId)

          if (customFormFieldsViewResp && customFormFieldsViewResp.resp_status) {
            this.vmCustomFormFieldsFormData = customFormFieldsViewResp.resp_data.data
          }
          if (this.vmCustomFormFieldsFormData.form_dropdown_structure) {
            this.formatJSON(null, this.vmCustomFormFieldsFormData.form_dropdown_structure)
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at customFormFieldsView() and Exception:", err.message)
      }
    },
    /**
     * customFormFieldsEdit
     */
    async customFormFieldsEdit () {
      try {
        if (this.invalidJSONMsg) {
          this.toastMsg = "Invalid Json in dropdown structure field"
          this.toastVariant = "danger"
          this.showToast = true
          return
        }
        this.cvLoadingStatus = true
        let customFormFieldsAddResp = await CustomFormFieldss.customFormFieldsEdit(this, this.vmCustomFormFieldsFormData)
        await ApiResponse.responseMessageDisplay(this, customFormFieldsAddResp)

        if (customFormFieldsAddResp && !customFormFieldsAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseCustomFormFieldsEditModal", this.vmCustomFormFieldsFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at customFormFieldsEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * formatJSON
     */
    formatJSON (event, unformattedJSON = null) {
      try {
        if (!unformattedJSON) {
          unformattedJSON = document.getElementById("json").value
        }
        let obj = JSON.parse(unformattedJSON)
        if (obj) {
          this.invalidJSONMsg = false
        }
        let formattedJSON = JSON.stringify(obj, undefined, 4)
        this.vmCustomFormFieldsFormData.form_dropdown_structure = formattedJSON
      }
      catch (err) {
        this.invalidJSONMsg = "Invalid JSON"
      }
    }
  }
}
</script>
<style scoped>
.textarea{
    height: 195px !important;
    overflow-y: scroll;
    line-height: 18px !important;
  }
</style>
